import Vue from "vue";
import VueRouter from "vue-router";

import SelectFunctions from "@/select/SelectFunctions";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",

    meta: {
      title: "Home",
    },

    component: () =>
      import(/* webpackChunkName: "landing" */ "../views/Landing.vue"),
  },

  {
    path: "/login",
    name: "Login",

    meta: {
      title: "Login",
    },

    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },

  {
    path: "/users",
    name: "User Management",
    component: () => import("../views/UserManagement"),
    meta: {
      title: "User Management",
    },
  },
  {
    path: "/usergroups",
    name: "User Group Management",
    component: () => import("../views/UserGroupManagement"),
    meta: {
      title: "User Group Management",
    },
  },

  {
    path: "/utilities/weights/reset",
    name: "User Group Management",
    component: () => import("../views/ResetBridgeWeights"),
    meta: {
      title: "Reset Weighbridge Readers",
    },
  },

  {
    path: "/reporting/ticketquery",
    name: "Ticket Queries Management",
    component: () => import("../views/TicketQuery"),
    meta: {
      title: "Ticket Query",
    },
  },
  {
    path: "/reporting/outboundUnder25TLoads",
    name: "Outbound Under 25T Loads",
    component: () => import("../views/OutboundUnder25TLoadsReport"),
    meta: {
      title: "Outbound Under 25T Loads Report",
    },
  },

  {
    path: "/transport/fleet",
    name: "Fleet Management",
    component: () => import("../views/FleetManagement"),
    meta: {
      title: "Fleet Management",
    },
  },

  {
    path: "/qc/documentlist",
    name: "QC Documents",
    component: () => import("../views/QCDocumentList"),
    meta: {
      title: "QC Documents",
    },
  },
  {
    path: "/transfernotes/documentlist",
    name: "Transfer Notes",
    component: () => import("../views/TransferNotes"),
    meta: {
      title: "Transfer Notes",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = (process.env.VUE_APP_APP_TITLE || "").concat(
    " - ".concat(to.meta.title)
  );
  SelectFunctions.checkLoggedIn()
    .then((result) => {
      console.log("checkLoggedIn result : ");
      console.log(result);
      if (result == "OK") {
        next();
      }
    })
    .catch((error) => {
      console.log("Check logged in error result : ");
      console.log(error);
      console.log("To Name:", to.name);
      console.log(to.name.toUpperCase() != "SIGNUP");
      if (
        to.name.toUpperCase() != "LOGIN" &&
        to.name.toUpperCase() != "SIGNUP" &&
        to.name.toUpperCase() != "FORGOTPASSWORD" &&
        to.name.toUpperCase() != "RESETPASSWORD"
      ) {
        console.log("Redirecting to login!");
        next("/login");
      } else {
        next(); // user is going to the login page anyway
      }
    });
});

export default router;
