<template>

  <v-app>

    <!--- CUSTOM CONFIRM DIALOG -->
    <v-dialog
      ref="confirm"
      xxxpersistent
      v-model="confirmVisible"
      :max-width="confirmOptions.width || '75%'"
    >
      <v-card
        :color="confirmOptions.color"
        dark
      >
        <v-card-text>
          <br />
          <span class="headline">
            {{confirmOptions.title || 'Please Confirm'}}
          </span>
          <br />
          <br />
          {{confirmText}}
        </v-card-text>
        <v-card-actions>
          <v-card-text>
            <v-container fluid>
              <v-layout
                row
                wrap
                align-end
                justify-end
              >
                <v-flex grow></v-flex>
                <v-flex shrink>
                  <v-btn
                    light
                    outlined
                    text
                    class="mx-2"
                    @click="confirmCallback(true)"
                    style="background-color:white;xcolor:Black;"
                  >{{confirmOptions.positiveText || "Confirm"}}</v-btn>
                </v-flex>
                <v-flex shrink>
                  <v-btn
                    outlined
                    class="mx-2"
                    @click="confirmCallback(false)"
                    xxxbackground-color="error"
                  >{{confirmOptions.negativeText || "Cancel"}}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- END CUSTOM CONFIRM DIALOG --->

    <v-navigation-drawer
      persistent
      :mini-variant="miniVariant"
      xx:clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      enable-resize-watcher
      fixed
      app
      v-if="menuVisible"
      dark
      dense
    >
      <v-list
        dense
        nav
        xclass="mt-6"
        dark
      >
        <v-list-item>

          <v-list-item-content>
            <v-toolbar-title>
              Menu
            </v-toolbar-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <template v-for="(menu,i) in menus">
          <template v-if="menu.sub && menu.sub.length > 0">
            <v-list-group
              :key="i"
              :prepend-icon="menu.icon"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{menu.name}}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(sub,x) in menu.sub"
                :to="sub.command"
                :key="x"
              >
                <v-list-item-icon>
                  <v-icon></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{sub.name}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else-if="menu.divider">
            <v-divider :key="i"></v-divider>
          </template>
          <template v-else>
            <v-list-item
              :to="menu.command"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon>{{menu.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{menu.name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      xx:clipped-left="$vuetify.breakpoint.lgAndUp"
      :color="$vuetify.breakpoint.mdAndDown ? '' : 'blue-grey lighten-5'"
      xdark
      v-if="menuVisible"
      :class="'elevation-0' "
      xxdense
    >

      <v-app-bar-nav-icon
        v-if="menuVisible"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="headline text-uppercase">
        {{title}}
      </v-toolbar-title>

    </v-app-bar>

    <v-main>

      <v-fade-transition mode="out-in">
        <router-view
          :key="$route.fullPath"
          scrollable
        ></router-view>
      </v-fade-transition>

      <!-- PROCESSING DIALOG -->
      <v-dialog
        ref="processingDialog"
        persistent
        v-model="processingVisible"
        :max-width="'40%'"
        style="z-index:1000;"
      >
        <div>
          <v-card>
            <v-card-text v-if="$vuetify.breakpoint.mdAndUp">
              <v-container fluid>
                <v-layout
                  xxjustify-center
                  xxxwrap
                >
                  <v-flex
                    xs4
                    ml-10
                  >
                    <v-progress-circular
                      indeterminate
                      large
                      color="primary"
                      size="50"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <div class="title mt-2">Processing....</div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-text v-else>
              <v-container>
                <v-layout
                  justify-center
                  xxxwrap
                >
                  <v-progress-circular
                    indeterminate
                    large
                    color="primary"
                    size="50"
                  />
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>

    </v-main>

    <!-- <v-container fluid>
      <v-main>
        <router-view />
      </v-main>
    </v-container> -->

    <!-- <v-app footer> -->

    <notifications
      group="global"
      position="top center"
    ></notifications>
  </v-app>

</template>

<script>

import SelectFunctions from './select/SelectFunctions';
import betterfetch from 'better-fetch';
export default {

  mounted () 
  {
    this.$root.$confirm = this.confirm;

    this.$router.afterEach(async (to, from) =>
    {
      this.checkLoggedIn();
      this.loadDBMenus();
    });
  },
  created: function ()
  {
    this.$root.fetch = betterfetch;
    this.$root.setTitle = (titleText) =>
    {
      this.title = titleText;
    };
    this.$root.setMenuVisible = (menuVisible) =>
    {
      this.menuVisible = menuVisible;
    };
    this.$root.setFooterVisible = (footerVisible) =>
    {
      this.footerVisible = footerVisible;
    };
    this.$root.setProfileVisible = (profileVisible) =>
    {
      this.profileVisible = profileVisible;
    };
    this.$root.setDarkMode = (darkMode) =>
    {
      this.darkMode = darkMode;
    };

    this.$root.showProcessing = () =>
    {
      this.processingVisible = true;
    }
    this.$root.hideProcessing = () =>
    {
      this.processingVisible = false;
    }

  },
  methods:
  {
    checkLoggedIn: function ()
    {
      SelectFunctions.checkLoggedIn()
        .then(result =>
        {
          if (result == 'OK')
          {
            console.log("Logged in.");
            this.loggedIn = true;
          }
        })
        .catch(error =>
        {
          this.loggedIn = false
        });
    },
    loadDBMenus: async function ()
    {

      let url = process.env.VUE_APP_BACKEND_ENDPOINT + 'adminmenus/';

      // if (this.areaKey)
      // {
      //   url = process.env.VUE_APP_BACKEND_ENDPOINT + `adminmenus/${ this.areaKey }`;
      // }

      let response = await SelectFunctions.fetch(
        url,
        { credentials: 'include' }
      );
      if (response.ok)
      {
        this.dbMenus = await response.json();
      }

      console.log('DB Menus : ', this.dbMenus);

    },

    confirm (text, options)
    {
      return new Promise((resolve, reject) =>
      {
        this.confirmText = text;
        this.confirmOptions = options || {};
        this.confirmVisible = true;

        this.confirmCallback = result =>
        {
          resolve(result);
          this.confirmVisible = false;
        };
      });
    },
  },

  computed:
  {
    menus ()
    {
      return [
        {
          name: 'Home',
          icon: 'mdi-home',
          command: '/'
        },
        ...this.dbMenus
      ]
    }
  },
  data: () => ({
    drawer: true,
    menuVisible: true,
    miniVariant: false,
    profileVisible: true,
    loggedIn: false,
    title: process.env.VUE_APP_APP_TITLE,
    dbMenus: [],
    areaKey: '',

    // Confirm dialog properties:
    confirmText: '',
    confirmOptions: {},
    confirmVisible: false,
    confirmCallback: null,


    globalSearch: '',
    showGlobalSearchMenu: false,

    // Processing dialog props
    processingVisible: false,

    // confirm dialog props:

    confirmText: "",
    confirmOptions: {},

    confirmVisible: false,

    confirmCallback: null,

  }),

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
